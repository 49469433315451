const Kachel = ({ href, img, title }) => {
  return (
    <a
      className="kachel bg-yellow-600 hover:bg-yellow-500 sm:w-40 sm:h-40 w-20 h-20 flex justify-center items-center flex-col rounded-lg text-sm md:text-xl transition shadow-xl"
      href={href}
    >
      <img className="kachel_img mb-2 w-8 h-8 sm:w-16 sm:h-16 object-contain" src={img} alt={title} />
      <div>{title}</div>
    </a>
  )
}

export default Kachel
