import Kachel from "./components/Kachel";

const random = Math.floor(Math.random() * (14 - 1 + 1) + 1);

function App() {
  return (
    <div className="app w-full h-full text-gray-200">
      <div
        className="bg fixed inset-0 bg-no-repeat bg-center bg-cover bg-black"
        style={{ backgroundImage: `url(./img/wallpaper/${random}.jpg)` }}
      ></div>
      <div className="mx-auto h-full flex flex-col justify-start items-center">
        <header
          className="text-6xl md:text-9xl text-center my-5 drop-shadow-lg"
          style={{ fontFamily: "monospace" }}
        >
          &lt;<span className="text-yellow-600">Team1</span>/&gt;
        </header>

        <section className="flex flex-wrap row-auto gap-3 md:gap-8 justify-evenly mx-6 lg:mx-60 sm:mx-12 mt-10 text-black font-semibold">
          <Kachel
            title="Logs"
            href="https://log.aws.zieglerhaus.de/_dashboards/app/discover#/view/44c1c4c0-a846-11ed-998d-9faf5ff970ed?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(columns:!(_source),filters:!(),index:'83464680-e0eb-11ec-a1e2-6f2dbf3b1a95',interval:auto,query:(language:kuery,query:''),sort:!())"
            img="./img/logos/logs.png"
          />
          <Kachel
            title="DEV"
            href="https://konfigurator.test.aws.zieglerhaus.de/"
            img="./img/logos/logo.png"
          />
          <Kachel
            title="STAG"
            href="https://konfigurator.staging.aws.zieglerhaus.de/"
            img="./img/logos/logo.png"
          />
          <Kachel
            title="PROD"
            href="https://mein.zieglerhaus.de/"
            img="./img/logos/logo.png"
          />
          <Kachel
            title="Admin Tool"
            href="https://konfigurator-admin.dev.aws.zieglerhaus.de"
            img="./img/logos/logo.png"
          />
          <Kachel
            title="Dashboard"
            href="https://grafana.prod.aws.zieglerhaus.de/d/BY_jqdYVk/konfigurator?orgId=1&var-abgeschlossen=ja&from=1681811836126&to=1686995836126"
            img="./img/logos/Grafana.svg"
          />
        </section>

        <section className="flex flex-wrap row-auto gap-3 md:gap-8 justify-evenly mx-6 lg:mx-60 sm:mx-12 mt-24 text-black font-semibold">
          <Kachel
            title="Jira"
            href="https://ziegler.atlassian.net/jira/projects?selectedProjectType=software"
            img="./img/logos/jira-3.svg"
          />
          <Kachel
            title="Confluence"
            href="https://ziegler.atlassian.net/wiki/home"
            img="./img/logos/confluence.svg"
          />

          <Kachel
            title="Gitlab"
            href="https://gitlab.com/zieglerhaussoftware"
            img="./img/logos/gitlab.svg"
          />
          <Kachel
            title="AWS"
            href="https://103128066250.signin.aws.amazon.com/console"
            img="./img/logos/aws-2.svg"
          />

          <Kachel
            title="Figma"
            href="https://www.figma.com/files/project/31615576/Konfigurator?fuid=994580835570923432"
            img="./img/logos/figma-1.svg"
          />
          <Kachel
            title="Sonarqube"
            href="https://sonarqube.aws.zieglerhaus.de/"
            img="./img/logos/sonarqube.png"
          />
          <Kachel
            title="Ethereal"
            href="https://ethereal.email/"
            img="./img/logos/ethereal.png"
          />
          <Kachel
            title="Deskbird"
            href="https://app.deskbird.com/planning/colleagues"
            img="./img/logos/deskbird.svg"
          />
        </section>
      </div>
    </div>
  );
}

export default App;
